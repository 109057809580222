import React from "react";
import { Page, Container } from "components/shared/styling";

export default function Weight() {
  return (
    <Page>
      <Container>
        <h1>Weight</h1>
        <p>Coming soon!</p>
      </Container>
    </Page>
  );
}
